<!--  -->
<template>
  <el-cascader
    ref="cascader"
    class="input_before"
    v-model="area"
    :options="resetList"
    :props="prop"
    :placeholder="holder"
    :disabled="disabled"
    clearable
    @change="getSelect"
  ></el-cascader>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    holder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: [String, Array]
  },
  data () {
    return {
      prop: {
        multiple: false,
        label: "name",
        value: "code",
        children: "childList",
        expandTrigger: 'hover'
      }
    };
  },

  components: {},

  computed: {
    ...mapGetters(['regionList']),
    area: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    resetList () {
      let optionArr = this.regionList
      optionArr = optionArr && optionArr.length ? optionArr.slice(0) : []
      optionArr.forEach(region => {
        region.childList && region.childList.length === 0 && delete region.childList
        if (region.childList) {
          region.childList.forEach(city => {
            city.childList && city.childList.length === 0 && delete city.childList
            if (city.childList) {
              city.childList.forEach(area => {
                  area.childList && area.childList.length === 0 && delete area.childList
              })
            }
          })
        }
      })
      return optionArr
    }
  },

  methods: {
    getSelect() {
      this.$nextTick(() => {
        let node = this.$refs.cascader.getCheckedNodes()
        let options = []
        function getLabel(data) {
          if(!data) return;
          options.unshift(data.label)
          if (data.parent) {
            getLabel(data.parent)
          }
        }
        getLabel(node[0])
        this.$emit('handleChange', options)
      })
    }
  }
}

</script>
<style lang='less' scoped>
</style>