<template>
	<div class="page-step-pre-info">
		<div class="custom-headline">入驻前信息</div>
		<el-card class="custom-card">
			<div class="custom-title">入驻模式选择</div>
			<el-form ref="preInfoFrom" label-width="220px" :model="preInfoForm" :rules="preInfoFormRules">
				<el-row>
					<el-col>
						<el-form-item label="" prop="entryType">
							<el-radio-group v-model="preInfoForm.entryType" size="medium">
								<el-radio label="1">入驻线上店铺
									<img :src="assets.img.mode.online" class="custom-img online">
								</el-radio>
								<el-radio label="2">入驻实体门店
									<img :src="assets.img.mode.offline" class="custom-img offline">
								</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<div v-if="preInfoForm.entryType == '2'">
					<el-row>
						<el-col :span="13">
							<el-form-item label="请选择要入驻实体门店的位置" prop="_entryAdressList">
								<RegionList style="width: 100%;" holder="请选择门店位置" v-model="preInfoForm._entryAdressList"
									@handleChange="handleAddressChange" />
								<div class="custom-mark">请按照营业执照上的注册地址填写</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="13">
							<el-form-item label="请填写具体位置" prop="entryAddress">
								<el-input v-model.trim="preInfoForm.entryAddress" placeholder="请填写具体位置" clearable>
								</el-input>
								<div class="custom-mark">请按照营业执照上的公司详细地址填写</div>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
				<div class="custom-title">入驻模式选择</div>
				<el-row>
					<el-col :span="13">
						<el-form-item label="经营类目" prop="_bizFormCode">
              <format-cascader @change="handleCascaderChanged" style="width:100%"></format-cascader>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="agreement-list">
				<p>请确保您充分知悉并符合以下基本入驻条件及说明，逐条确认后即可申请入驻</p>
				<el-row class="agreement-item">
					<el-checkbox v-model="agreementCheckList[0]">数字证书使用协议
					</el-checkbox>
					<div class="mask" @click.stop="handleDigitalCertShow"></div>
				</el-row>
				<el-row class="agreement-item">
					<el-checkbox v-model="agreementCheckList[1]">提交的信息和证明文件的真实性</el-checkbox>
				</el-row>
				<el-row class="agreement-item">
					<el-checkbox v-model="agreementCheckList[2]">在国美家平台出售的商品符合国家质量标准，不侵犯他人知识产权</el-checkbox>
				</el-row>
				<el-row class="agreement-item">
					<el-checkbox v-model="agreementCheckList[3]">
						国美家有权对商户信息和证明文件进行不定时的抽查，以核实其真实性、合法性和效力状态，国美家有权就前述目的要求提交更多信息或证明文件</el-checkbox>
				</el-row>
			</div>
			<div class="custom-action-box">
				<el-button class="btn" size="small" type="primary" :loading="submitPendingFlag" @click="jump2nextStep">
					下一步
				</el-button>
			</div>
		</el-card>
		<!-- 弹窗 - 数字证书使用协议 -->
		<el-dialog custom-class="custom-dialog" title="数字证书使用协议" top="6vh" :visible.sync="digitalCertDialogFlag"
			width="70%" center :close-on-click-modal="false" :close-on-press-escape="false">
			<div class="dialog-container">
				<Agreement></Agreement>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="dialog-btn" type="primary" v-if="remainTime > 0">倒计时（{{remainTime}}s）</el-button>
				<el-button class="dialog-btn" type="primary" v-else @click="handleDigitalCertStatus">确认</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RegionList from "@/components/RegionList";
import {
	getJoinAllFormInfo,
	submitPreInfoForm,
} from "@/api/api-join-mall";
import { JoinFrom } from "@/utils/cache";
import Agreement from "../components/Agreement";
import FormatCascader from "@/components/FormatCascader";
export default {
	components: {
    FormatCascader,
		RegionList,
		Agreement,
	},
	computed: {
		...mapGetters(["regionList"]),
	},
	data() {
		return {
			// 静态资源
			assets: {
				img: {
					mode: {
						online: require("../assets/img/mode_online.png"),
						offline: require("../assets/img/mode_offline.png"),
					},
				},
			},
			// 经营类目选择器
			bizformDictionaryProps: {
				multiple: true,
				value: "code",
				label: "name",
				children: "childCat",
				// checkStrictly: true // 选择任意级
			},
			specCode: "6000000354165177",
			// 表单
			preInfoForm: {
				entryType: "1", // ONLINE-入驻线上店铺 OFFLINE-入驻实体门店
				entryProvince: "",
				entryCity: "",
				entryArea: "",
				_entryAdressList: [],
				entryAddress: "",
				_bizFormCode: [],
				bizFormCode: [],
			},
			preInfoFormRules: {
				_entryAdressList: [
					{
						type: "array",
						required: true,
						message: "请选择门店位置",
						trigger: "change",
					},
				],
				_bizFormCode: [
					{
						type: "array",
						required: true,
						message: "请选择经营类目",
						trigger: "change",
					},
				],
			},
			// 协议勾选状态
			agreementCheckList: [false, false, false, false],
			// 数字协议弹窗
			digitalCertDialogFlag: false,
			timer: null,
			remainTime: 10,
			// 操作
			submitPendingFlag: false,
		};
	},
	methods: {
		...mapActions(["getRegionList"]),
    handleCascaderChanged(value, valueList) {
      console.log(valueList)
      this.preInfoForm._bizFormCode = []
      valueList.forEach(item => {
        this.preInfoForm._bizFormCode.push(...item.oldCaInfoList.map(i => i.id))
      })
      this.preInfoForm._bizFormCode = Array.from(new Set(this.preInfoForm._bizFormCode))
    },
		/**
		 * 恢复缓存信息
		 */
		recoveryCache(system = {}) {
			JoinFrom.set(system);
			let cache = JoinFrom.get();
			// 判断  && 更新缓存
			if (cache.bizFormCode) {
				let temp = {};
				for (let key in this.preInfoForm) {
					if (
						!key.includes("_") &&
						typeof cache[key] != "undefined"
					) {
						this.$set(temp, key, cache[key]);
					}
				}
				Object.assign(this.preInfoForm, temp);
				// 格式化中间变量
				this.formatMiddleVariable();
				// 协议勾选
				this.agreementCheckList = [true, true, true, true];
			}
		},
		async getJoinAllFormInfo() {
			try {
				let res = await getJoinAllFormInfo();
				if (res.data.code === 0) {
					// 1-未提交 2-已提交
					if (res.data.data.submitStatus == 2) {
						this.$router.push({
							name: "StatusSubmitted",
						});
					}
					this.recoveryCache(res.data.data);
				} else {
					this.recoveryCache();
				}
			} catch (error) {
				this.recoveryCache();
			}
		},
		/**
		 * 兼容 - 返显
		 */
		formatMiddleVariable() {
			// 地址
			let _entryAdressList = [
				this.preInfoForm.entryProvince,
				this.preInfoForm.entryCity,
				this.preInfoForm.entryArea,
			];
			if (
				!_entryAdressList.includes("") &&
				!_entryAdressList.includes(null) &&
				this.preInfoForm._entryAdressList.length === 0
			) {
				// 有数据 && 无缓存
				this.preInfoForm._entryAdressList =
					this._formatAddress2code(_entryAdressList);
			}
			// 经营类目
			this.preInfoForm._bizFormCode =
				this.preInfoForm.bizFormCode === null ||
				this.preInfoForm.bizFormCode.length === 0
					? []
					: JSON.parse(this.preInfoForm.bizFormCode);
		},
		/**
		 * 地址选择器 - 回显
		 */
		_getCodeByName(list, name) {
			let nextLevel = {};
			if (list instanceof Array && list.length > 0) {
				list.some((el) => {
					if (el.name === name) {
						nextLevel.code = el.code;
						nextLevel.list = el.childList;
						return true;
					}
				});
			}
			return nextLevel;
		},
		_formatAddress2code(addressArr = []) {
			if (addressArr.length !== 3) {
				console.warn("地址字段不合法，请检查");
				return;
			}
			let codeArr = [],
				nextLevel = {
					list: this.regionList,
				};
			for (let i = 0, len = addressArr.length; i < len; i++) {
				nextLevel = this._getCodeByName(nextLevel.list, addressArr[i]);
				codeArr.push(nextLevel.code);
			}
			return codeArr;
		},
		/**
		 * 地址选择器 - 回调
		 */
		handleAddressChange(arr) {
			this.preInfoForm.entryProvince = arr[0];
			this.preInfoForm.entryCity = arr[1];
			this.preInfoForm.entryArea = arr[2];
		},
		/**
		 * 数字证书协议展示
		 */
		_clearTimer() {
			clearInterval(this.timer);
		},
		handleDigitalCertShow() {
			if (this.agreementCheckList[0]) {
				this.$set(this.agreementCheckList, 0, false);
			} else {
				if (this.timer) {
					this._clearTimer();
				}
				this.remainTime = 10;
				this.digitalCertDialogFlag = true;
				this.timer = setInterval(() => {
					this.remainTime--;
					if (this.remainTime < 1) this._clearTimer();
				}, 1000);
			}
		},
		/**
		 * 更新数字证书勾选状态
		 */
		handleDigitalCertStatus() {
			this.digitalCertDialogFlag = false;
			this.$set(this.agreementCheckList, 0, true);
		},
		/**
		 * 校验全部协议勾选状态
		 */
		getAgreementsCheckStatus() {
			return !this.agreementCheckList.some((el) => !el);
		},
		/**
		 * 下一步
		 */
		formatSumbitParams() {
			let preInfoForm = JSON.parse(JSON.stringify(this.preInfoForm));
			// 经营类目
			preInfoForm.bizFormCode = JSON.stringify(preInfoForm._bizFormCode);
			// 删除中间变量
			for (let key in preInfoForm) {
				if (key.includes("_")) {
					delete preInfoForm[key];
				}
			}
			return preInfoForm;
		},
		jump2nextStep() {
			this.$refs["preInfoFrom"].validate((valid) => {
				if (valid) {
					let agreementsCheckAll = this.getAgreementsCheckStatus();
					if (agreementsCheckAll) {
						this.submitPendingFlag = true;
						let preInfoForm = this.formatSumbitParams();
						JoinFrom.set({ ...this.preInfoForm, ...preInfoForm });
						submitPreInfoForm(preInfoForm)
							.then((res) => {
								if (res.data.code === 0) {
									this.$router.push({
										name: "StepContactInfo",
									});
								} else {
									this.$message.warning(res.data.message);
								}
								this.submitPendingFlag = false;
							})
							.catch(() => {
								this.submitPendingFlag = false;
							});
					} else {
						this.$message.warning("请勾选入驻相关的协议和条件");
					}
				}
			});
		},
	},
	async created() {
		// 获取地址选择器数据
		try {
			await this.getRegionList();
		} catch (error) {
			this.$notify.warning({
				title: "提示",
				message: "请先登录",
				offset: 80,
			});
			// this.$router.push({
			// 	name: 'MallIndex'
			// })
		}
		// 获取全量信息
		this.getJoinAllFormInfo();
	},
	beforeDestroy() {
		this._clearTimer();
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base.scss";
::v-deep {
	.el-checkbox__input {
		vertical-align: top;
	}
	.custom-dialog {
		.el-dialog__title {
			font-size: 15pt;
			font-weight: bold;
		}
	}
}
.page-step-pre-info {
	.el-radio-group {
		padding-top: 147px;
		padding-bottom: 5px;
		.el-radio {
			width: 170px;
			text-align: center;
			.custom-img {
				position: absolute;
				top: -145px;
				left: 0;
				right: 0;
				width: 170px;
				height: 133px;
			}
		}
	}
	.agreement-list {
		margin-left: 196px;
		width: 66%;
		p {
			margin: 3px 0 25px 25px;
			font-size: 14px;
			color: #ee9118;
		}
		.agreement-item {
			position: relative;
			margin-bottom: 25px;
			.mask {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
				cursor: pointer;
				z-index: 9;
			}
		}
	}
	.dialog-container {
		max-height: 460px;
		overflow-y: scroll;
	}
	.dialog-btn {
		width: 126px;
	}
	.mask {
		width: 200px;
	}
}
</style>
